.kanjiWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 200px;
}
.title {
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.kanjiCard {
  background-color: crimson;
  color: white;
  width: 300px;
  height: 250px;
  margin: 10px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}

.kanji-title {
  font-size: 4rem;
  align-self: center;
}

.learnBtn {
  background-color: white;
  padding: 5px;
  width: 100px;
  color: red;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgb(119, 111, 111);
  font-size: 1.5rem;
}

.shuffle-box{
  background-color: crimson;
  width: 150px;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 20px;
  border-radius: 8px;
  padding: 5px;
  font-size: 1.45rem;
  color: white;
  transition: 0.3s ease-in-out;
  margin-bottom: 50px;
}

.shuffle-box:hover{
  background-color: rgb(156, 12, 41);
  border: 1px solid white;
  cursor: pointer;
}

.repeat{
  margin-left: 12px;
}



@media screen and (min-width: 492px){
  
}

@media screen and (min-width: 628px){
  .kanjiWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 928px){
  .kanjiWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 200px;
  }

  .kanjiCard {
    background-color: crimson;
    color: white;
    width: 400px;
    height: 350px;
    margin: 10px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
  }

  .kanji-title {
    font-size: 6rem;
    align-self: center;
  }

  .learnBtn {
    background-color: white;
    padding: 5px;
    width: 200px;
    color: red;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgb(119, 111, 111);
    font-size: 1.5rem;
  }
}