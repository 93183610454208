.navbar {
  color: crimson;
  height: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.logo {
  align-self: center;
  align-items: center;
  width: 80px;
  margin-left: 20px;
  margin-top: 15px;
  padding: 0;
  background-color: crimson;
  border-radius: 50%;
}

.logo-day {
    align-self: center;
    align-items: center;
    width: 80px;
    margin-left: 20px;
    margin-top: 15px;
    padding: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
  }


.logo-pic {
  transform: scale(1.2);
}

.toggleIcon{
  margin-left: 12px;
}

.logo-title {
  margin-top: 15px;
  align-self: center;
  align-items: center;
  margin-left: 10px;
  font-size: 2rem;
  font-family: 'Caveat Brush', cursive;
  text-transform: uppercase;
  font-weight: 500;
  
}
.headerContainer {
  margin-left: auto;
  margin-right: 3rem;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-size: 1em;
}

.hamburguerIcon {
  margin-left: auto;
  cursor: pointer;
  transform: scale(1.2);
}

.desktopHeader {
  display: none;
}

.authBtn {
  background-color: rgb(36, 33, 33);
  color: white;
  width: 110px;
  height: 35px;
  margin: 10px;
}

.aboutLink {
  text-transform: uppercase;
}

@media screen and (min-width: 928px) {
  .hamburguerIcon {
    display: none;
  }

  .desktopHeader {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
  }

  .aboutLink {
    margin-right: 17px;
    margin: 15px;
  }
}
