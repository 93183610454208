


.logo-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 0;
   
}

.logo-sidebar{
   width: 70px;
   top: 0;
   margin-bottom: 0;
   transform: scale(1.2);
   background-color: transparent;
}

.logo-container span{
    margin-top: -8px;
}

.logo-container small{
    height: 20px;
    font-size: 1rem;
    line-height: 30px;
    z-index: 10;
    
}