
.wrapper{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 80%;
    margin: auto;
    margin-top: 70px;
    height: auto;
    border-radius: 9px;
    margin-bottom: 80px;
    
    
}

.kanji{
    display: flex;
    background-color: rgb(220, 20, 60);
    border-radius: 50%;
    color: white;
    height: 150px;
    margin: 10px;
    width: 150px;
    align-items: center;
    align-self: center;
    justify-content: center;
    font-size: 4em;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;

}

.grade{
    color: crimson;
    font-weight: 700;
    padding: 3px;
    margin: 10px;
}

.details{
    padding: 3px;
    margin: 10px;
}

.meanings-box{
    margin: 10px;
    padding: 3px;
}

.meaningFormatting{
    display: inline-block;
    margin-left: 0px;
    
}

.info-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.2rem;
    padding: 5px;
    margin-top: 20px;
}

.details-wrapper{
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    align-self: center;
    font-size: 1.4rem;
}


.meanings-wrapper{
    font-size: 1.4rem;
    padding: 4px;
    width: 100%;
    align-self: center;
    margin-bottom: 10px;
}

.backBtn{
    background-color: crimson;
    width: 300px;
    height: 80px;
    font-size: 2em;
    padding: 8px;
    border-radius: 8px;
    color: white;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 150px;

}