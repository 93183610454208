

.footer{
    position: absolute;
    background-color: rgb(40, 37, 37);
    color: white;
    height: 120px;
    display: flex;
    bottom: 0;
    width: 100%;
    margin-top: 100px;
    flex-direction: column;
    justify-content: center;
}

.footer .text{
    display: flex;
    align-items: center;
    align-self: center;
    
}

.footer .socials {
    display: flex;
    align-items: center;
    align-self: center;
}

.socials a{
    margin: 5px;
}