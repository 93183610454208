.not-found-container{
    max-height: 100px !important;
    display: flex;
    height: 50vh;
    flex-direction: column;
    justify-content: center;
    margin-top: 10rem;
}

.not-found-text{
    align-items: center;
    align-self: center;
    font-size: 2.5em;
    
}

.not-found-container button {
    background-color: crimson;
    color: white;
    padding: 8px;
    border-radius: 9px;
    width: 200px;
    height: 100px;
    font-size: 1.7rem;
    align-self: center;
    margin-top: 40px;
}