.container{
    display: flex;
    flex-direction: column;
}

.homepageBg{
    width: 340px;
    display: flex;
    margin: auto;
    height: 200px;
    object-fit: cover;
    margin-bottom: -10px;
    margin-top: 20px;
    border-radius: 5px;
}

.kanji-subtitle{
    text-align: left;
    margin-left: 10%;
    margin-top: 100px;
    margin-bottom: 60px;
    font-size: 2em;
}

@media only screen and (min-width: 492px){
    .homepageBg{
        width: 85vw;
        display: flex;
        margin: auto;
        height: 200px;
        object-fit: cover;
        margin-bottom: -10px;
        margin-top: 20px;
        border-radius: 10px;
    }
    
}

@media only screen and (min-width: 928px){
    .homepageBg{
        width: 900px;
        display: flex;
        margin: auto;
        height: 200px;
        object-fit: cover;
        margin-bottom: -10px;
        margin-top: 20px;
        border-radius: 12px;
    }
    
}