.about{
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    height: auto;
    text-align: justify;
    font-size:1.4em;
    justify-content: center;
}

.about-title{
    color:crimson;
    font-size: 2rem;
    font-weight:bold;
    margin-bottom: 20px;

}

.about-intro{
    width: 80%;
    margin-bottom: 100px;
}
.phase-box{
    margin-top: 12px;
    margin-bottom: 50px;
    width: 80%;

}

.phase-box h3{
    color: crimson;
    font-weight:bold;
    font-size: 1.5rem;
}