.about-container {
  background-color: rgb(211, 184, 184);
  display: flex;
  max-height: 50vh;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-bottom: 300px;
  margin-top: -30px;
}

.header-image{
    position: absolute;
    width: 100%;
    margin-bottom: 100px;
    display: flex;
}

.header-image img{
    height: 35vh;
    width: 100%;
    z-index:0;
    object-fit:cover;
}

.header-title{
    
    z-index: 250;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: auto;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
}

.header-title button{
    background-color: crimson;
    color: white;
    width: 100px;
    padding: 8px;
    margin-top: 10px;
    
}

.about-container h1 {
  font-size: 2em;
  align-self: center;
  width: 100%;

}
